function injectBarreCgos () {
    let css
    let urlSogec
    let urlPM
    let urlEspacePersonnel
    let urlServicesplus
    let urlVacances
    let urlCSS
    if (process.env.NODE_ENV === 'production') {
        urlCSS = 'https://pm.cgos.info/cdn/cgosBarrePM.css'
        urlVacances = 'https://pm.cgos.info/vacances'
        urlServicesplus = 'https://pm.cgos.info/services-plus'
        urlEspacePersonnel = 'https://espace-personnel.cgos.info/'
        urlPM = 'https://pm.cgos.info'
        urlSogec = 'https://avantages-loisirs.cgos.info'
        css = `<link rel="stylesheet" href="${urlCSS}" />`
    } else {
        urlCSS = 'https://preprod-pm.cgos.info/cdn/cgosBarrePM.css'
        urlVacances = 'https://preprod-pm.cgos.info/vacances'
        urlServicesplus = 'https://preprod-pm.cgos.info/services-plus'
        urlEspacePersonnel = 'https://espace-personnel-preprod789.cgos.info/'
        urlSogec = 'https://avantages-loisirs-newpreprod.cgos.info'
        urlPM = 'https://preprod-pm.cgos.info'
        css = `<link rel="stylesheet" href="${urlCSS}" />`
    }

    var navbarHTML = `<!--CGOS BARRE PM-->

        <header class="cgos-barre-pm-header">
            <i id="cgos_mobile_nav_menu_toggle" class="fas fa fa-bars icon_menu"></i>
            <div class="main-barrePM">
                <div class="logo">
                    <a href="${urlPM}/">
                    <img src="${urlPM}/img/barre_cgos_pm_logo.png" alt="">
                    </a>
                </div>
                <div class="menu-barrePM">
                    <nav class="main-nav-barrePM">
                        <ul class="">
                            <li class="link"><a href="${urlSogec}/billetterie">Billetterie</a></li>
                            <li class="link"><a href="${urlSogec}/loisirs">Loisirs</a></li>
                            <li class="link"><a href="${urlVacances}">Vacances</a></li>
                            <li class="link"><a href="${urlSogec}/avantages">Avantages conso</a></li>
                            <li class="link"><a href="${urlServicesplus}">Services+</a></li>
                        </ul>
                    </nav>
                </div>
            </div>
            <div class="espace-perso-barrePM">
            <a href="${urlEspacePersonnel}">
                <img src="${urlPM}/img/ico-compte.png" alt="icon espace personnel">
                <p>Espace personnel</p>
            </a>
        </div>
        </header>
        <nav class="cgos_mobile_nav_menu" id="cgos_mobile_nav_menu">
            <ul>
                <li><a href="${urlSogec}/billetterie">Billetterie</a></li>
                <li><a href="${urlSogec}/loisirs">Loisirs</a></li>
                <li><a href="${urlVacances}">Vacances</a></li>
                <li><a href="${urlSogec}/avantages">Avantages conso</a></li>
                <li><a href="${urlServicesplus}">Services+</a></li>
            </ul>
        </nav>
        <!--END CGOS BARRE PM-->
        `

    document.head.insertAdjacentHTML('afterbegin', css)
    document.body.insertAdjacentHTML('afterbegin', navbarHTML)
    var mainNav = document.getElementById('cgos_mobile_nav_menu')
    var navBarToggle = document.getElementById('cgos_mobile_nav_menu_toggle')
    /* Toggle mobile menu */
    navBarToggle.addEventListener('click', function () {
        mainNav.classList.toggle('active')

        if (navBarToggle.classList.contains('fa-bars')) {
            navBarToggle.classList.remove('fa-bars')
            navBarToggle.classList.add('fa-times')
            return
        }
        if (navBarToggle.classList.contains('fa-times')) {
            navBarToggle.classList.remove('fa-times')
            navBarToggle.classList.add('fa-bars')
        }
    })
}

//POLYFILL FOR IE version <  11
if (!document.currentScript) {
    document.currentScript = (function () {
        var scripts = document.getElementsByTagName('script')
        return scripts[scripts.length - 1]
    })()
}
console.log(document.currentScript.getAttribute('noIframe') )
let cgos_barre_noIframe = false //Default Value

if (document.currentScript.getAttribute('noIframe') === '1') {
    cgos_barre_noIframe = true
}

let is_inIframe_cgos_barre = function () {
    try {
        return (window.self !== window.top)
    } catch (e) {
        return true
    }
}

if (cgos_barre_noIframe && is_inIframe_cgos_barre()) {
// In iframe do nothing
} else {

    if (document.readyState == 'interactive' || document.readyState == 'complete') {
        injectBarreCgos()
    } else {
        document.onreadystatechange = function () {
            if (document.readyState == 'interactive') {
                injectBarreCgos()
            }
        }
    }
}
